import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/post-layout.js";
import { HashLink } from '../components/link';
import complexityScaleImg from '../images/complexity-scale.png';
import devSpeedGraphImg from '../images/dev-speed.png';
import knittedCastleImg from '../images/knitted-castle.png';
import composableComponentsImg from '../images/composable-components.png';
import modularComponentsImg from '../images/modular-components.png';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Simple Made Easy by Rich Hickey`}</h1>
    <p>{`In `}<a parentName="p" {...{
        "href": "https://www.infoq.com/presentations/Simple-Made-Easy"
      }}>{`the talk titled, “Simple Made Easy”`}</a>{`, Rich Hickey emphasizes simplicity’s virtues over easiness’. He shows that while many choose easiness, they may end up with complexity. The better way is to choose easiness along the simplicity path.`}</p>
    <p>{`The term “simple” is often erroneously mistaken for “easy”. `}</p>
    <p>{`"Easy" means "to be near”, “to be at hand", or "to be approachable". The term “easy” is subjective and relative. When something is easy it’s familiar. `}</p>
    <p>{`"Simple" is the opposite of "complex" which means "being intertwined" or "being tied together". Simple != “I already know what it means”. Simple != easy.`}</p>
    <div style={{
      "display": "flex",
      "justifyContent": "center"
    }}>
  <img src={complexityScaleImg} alt="Six images of 4 strands gradually becoming more intertwined" width="40%" height="100%" />
    </div>
    <p>{`When a system is simple it has “one fold” or “one braid”. The system has a lack of interleaving in contrast with a complex, “braided” system.`}</p>
    <p>{`Just because I can’t understand German does that mean German is unreadable? No, I just don’t know German.`}</p>
    <p>{`“If you want everything to be familiar, you will never learn anything new.”`}</p>
    <h2><HashLink id="reasonable" to="/simple-made-easy#reasonable" mdxType="HashLink">{`Reasonable software`}</HashLink></h2>
    <p>{`If possible, simplify the problem space before you start.`}</p>
    <p>{`Humans can only hold so much information in working memory. This fundamentally limits our ability to understand complex (braided) systems.`}</p>
    <p>{`Your ability to reason about your program is critical to changing it without fear. Also, your ability to reason about your program is critical to debugging.`}</p>
    <p>{`“What’s true of every bug in the field? It passed all the tests.”`}</p>
    <h2><HashLink id="dev-speed" to="/simple-made-easy#dev-speed" mdxType="HashLink">{`Development speed`}</HashLink></h2>
    <p>{`Rich made a great joke in this talk which also carries a lot of truth:`}</p>
    <blockquote>
      <p parentName="blockquote">{`What kind of runner can run as fast as they possibly can from the very start of a race? Only somebody who runs really short races.`}</p>
      <p parentName="blockquote">{`But of course we’re programmers and we are smarter than runners, apparently, because we know how to fix that problem. We just fire the starting pistol every 100 yards and call it a new sprint!”`}</p>
    </blockquote>
    <div style={{
      "display": "flex",
      "justifyContent": "center"
    }}>
  <img src={devSpeedGraphImg} alt="Graph depicting development slowing down over time when using easy constructs whereas development speeds up over time using simple constructs" width="60%" height="100%" />
    </div>
    <p>{`Prioritizing ease often results in early speed that tapers over time. If you ignore complexity it’s inevitable that you will slow down over the long haul. Whereas prioritizing simplicity often starts out slower but allows us to pick up speed over time.`}</p>
    <p>{`This line of thinking isn’t really applicable for trivial or throwaway projects.`}</p>
    <h2><HashLink id="road" to="/simple-made-easy#road" mdxType="HashLink">{`The road to simplicity`}</HashLink></h2>
    <p>{`The benefits of simplicity include flexibility as well as the ease of understanding, change and debugging.`}</p>
    <div style={{
      "display": "flex",
      "justifyContent": "center"
    }}>
  <img src={knittedCastleImg} alt="A photo of a knitted castle next to a lego castle" width="70%" height="100%" />
    </div>
    <p>{`A knitted castle is much harder to change than a lego castle.`}</p>
    <p>{`How can we make things easy? We can bring something to hand by installing it. Or we can become familiar with something by trying and learning.`}</p>
    <p>{`Compared to the complexity we can create, we’re very limited in our ability to understand it. We need to focus on making things easier to understand by simplifying them.`}</p>
    <p>{`To gain simplicity:`}</p>
    <ul>
      <li parentName="ul">{`Choose simple tools`}</li>
      <li parentName="ul">{`Write simple stuff`}</li>
      <li parentName="ul">{`Simplify other people’s stuff (problem space or code)`}
        <ul parentName="li">
          <li parentName="ul">{`Disentangle the complexity`}</li>
        </ul>
      </li>
    </ul>
    <p>{`“The bottom line is simplicity is a choice. It’s your fault if you don’t have a simple system.”`}</p>
    <h3><HashLink id="tools" to="/simple-made-easy#tools" mdxType="HashLink">{`Choose simple tools`}</HashLink></h3>
    <p>{`As programmers we tend to focus on the benefit of things rather than cost/benefit analysis. The first step to a simpler life is to choose simpler tools.`}</p>
    <table>
  <tr>
   <td><strong>Construct</strong>
   </td>
   <td><strong>Get it via</strong>
   </td>
  </tr>
  <tr>
   <td>Values
   </td>
   <td>Final, persistent collections
   </td>
  </tr>
  <tr>
   <td>Functions
   </td>
   <td>Stateless methods
   </td>
  </tr>
  <tr>
   <td>Namespaces
   </td>
   <td>Language support
   </td>
  </tr>
  <tr>
   <td>Data
   </td>
   <td>Maps, arrays, sets, XML, JSON, etc.
   </td>
  </tr>
  <tr>
   <td>Polymorphism a la carte
   </td>
   <td>Clojure protocols / Haskell type classes
   </td>
  </tr>
  <tr>
   <td>Managed refs
   </td>
   <td>Clojure / Haskell refs
   </td>
  </tr>
  <tr>
   <td>Set functions
   </td>
   <td>Libraries
   </td>
  </tr>
  <tr>
   <td>Queues
   </td>
   <td>Libraries
   </td>
  </tr>
  <tr>
   <td>Declarative data manipulation
   </td>
   <td>SQL / LINQ / Datalog
   </td>
  </tr>
  <tr>
   <td>Rules
   </td>
   <td>Libraries / Prolog
   </td>
  </tr>
  <tr>
   <td>Consistency
   </td>
   <td>Transactions / Values
   </td>
  </tr>
    </table>
    <p>{`Sometimes we focus on experience of use of construct (a piece of syntax made up of smaller pieces) rather than the long term results of use like software quality and maintenance.`}</p>
    <p>{`We must assess the constructs we use by their artifacts.`}</p>
    <h3><HashLink id="write-simple" to="/simple-made-easy#write-simple" mdxType="HashLink">{`Write simple stuff`}</HashLink></h3>
    <p>{`Your tools only get you so far. Programs are built by thinking more so than typing. We need to abstract for simplicity.`}</p>
    <p>{`Form abstractions from related, `}<strong parentName="p">{`small`}</strong>{` sets of functions. Strive to have many composable subcomponents. Favor rules and declarative logic systems over conditionals that make control flow complex.`}</p>
    <div style={{
      "display": "flex",
      "justifyContent": "center"
    }}>
  <img src={composableComponentsImg} alt="Two legos stacked on top of one another and almost connected" width="30%" height="100%" />
    </div>
    <p>{`Do not intertwine “what” your application is doing with “how” it’s doing it. If A does something and then calls B it’s too complex. Use a queue instead.`}</p>
    <p>{`Contrary to popular belief, simplicity often means making more things, not fewer.`}</p>
    <div style={{
      "display": "flex",
      "justifyContent": "center"
    }}>
  <img src={modularComponentsImg} alt="Two legos with thought bubbles depicting only the connector which serves as a metaphor for a simple abstraction" width="90%" height="100%" />
    </div>
    <p>{`Don’t be fooled by code organization. Partitioning and categorizing our code doesn't imply simplicity. However, modular software is enabled by simplicity.`}</p>
    <h2><HashLink id="easy" to="/simple-made-easy#easy" mdxType="HashLink">{`Simplicity Made Easy`}</HashLink></h2>
    <p>{`Focus on the artifact (the program) rather than the authoring of the software.`}</p>
    <p>{`Important questions when it comes to building software are: `}</p>
    <ul>
      <li parentName="ul">{`Does the software do what is supposed to do? `}</li>
      <li parentName="ul">{`Is it of high quality? `}</li>
      <li parentName="ul">{`Can we rely on it? `}</li>
      <li parentName="ul">{`Can problems be fixed along the way? `}</li>
      <li parentName="ul">{`Can requirements change over time? `}</li>
    </ul>
    <p>{`The answers to these questions are what matters in writing software, not the look and feel of the experience writing the code or the cultural implications of it.`}</p>
    <p>{`“Simplicity is the ultimate sophistication.” - Leonardo da Vinci`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      